// // src/App.js
// // import React from 'react';
// // import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// // import { AuthProvider } from './components/firebase/authContext';
// // import ProtectedRoute from './components/ProtectedRoute';
// // import Navbar from './components/Navbar';


// // import Paywall from './components/Paywall';
// // import Success from './components/Success';
// // import Cancel from './components/Cancel';
// // import Pick4Generator from './components/Pick4Generator';
// // import Login from './components/Login';
// // import SignUp from './components/SignUp';
// // import Logout from './components/Logout';
// // import SignInWithEmailLink from './components/SignInWithEmailLink';
// // import FinishSignIn from './components/FinishSignIn';
// // import PrivacyPolicy from './components/PrivacyPolicy';
// // import TermsOfService from './components/TermsOfService';
// // import Profile from './components/Profile';
// // import ResetPassword from './components/ResetPassword';
// // import LotteryBooks from './components/LotteryBooks';
// // import WinningTheLotterySalesPage from './components/WinningTheLotterySalesPage';
// // import Pick3LotteryStrategyWorkbookSalesPage from './components/Pick3LotteryStrategyWorkbookSalesPage';
// // import Pick3LotteryBookSalesPage from './components/Pick3LotteryBookSalesPage';
// // import Pick3Lottery962SalesPage from './components/Pick3Lottery962SalesPage';
// // import Pick4Lottery3693SalesPage from './components/Pick4Lottery3693SalesPage';
// // import Pick3Lottery317RundownSalesPage from './components/Pick3Lottery317RundownSalesPage';
// // import SuperPick3WorkoutSalesPage from './components/SuperPick3WorkoutSalesPage';
// // import Pick369RundownSalesPage from './components/Pick369RundownSalesPage';
// // import Pick4StrategyTexasSalesPage from './components/Pick4StrategyTexasSalesPage';
// // import Pick4LargeFormat3693SalesPage from './components/Pick4LargeFormat3693SalesPage';
// // import Cash4LotteryStrategySalesPage from './components/Cash4LotteryStrategySalesPage';
// // import Pick3MasterySalesPage from './components/Pick3MasterySalesPage';
// // import Cash4LargeFormatSalesPage from './components/Cash4LargeFormatSalesPage';
// // import Pick3SpanishSalesPage from './components/Pick3SpanishSalesPage';
// // import LotteryLivingSalesPage from './components/LotteryLivingSalesPage';
// // import Pick3Generator from './components/Pick3Generator';
// // import Grid4 from './components/Grid4';
// // import Pick3StateGenerator from './components/Pick3StateGenerator';
// // import SevenThirtyRundownMethod from './components/SevenThirtyRundownMethod';
// // import ThreeSixNineThreeGenerator from './components/ThreeSixNineThreeGenerator';
// // import HomePage from './components/HomePage';
// // import GridComponent from './components/GridComponent';

// function App() {
//   return (
//     <AuthProvider>
//       <Router>
//         <Navbar />
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/logout" element={<Logout />} />
//           <Route path="/signin-with-email" element={<SignInWithEmailLink />} />
//           <Route path="/finishSignIn" element={<FinishSignIn />} />
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           <Route path="/terms-of-service" element={<TermsOfService />} />
//           <Route path="/profile" element={<Profile />} />
//           <Route path="/reset-password" element={<ResetPassword />} />
//           <Route path="/resources" element={<LotteryBooks />} />
//           <Route path="/winning-the-lottery" element={<WinningTheLotterySalesPage />} />
//           <Route path="/how-to-make-a-living-playing-the-lottery" element={<LotteryLivingSalesPage />} />
//           <Route path="/pick-3-spanish" element={<Pick3SpanishSalesPage />} />
//           <Route path="/master-730-rundown" element={<Pick3LotteryBookSalesPage />} />
//           <Route path="/pick-3-962-method" element={<Pick3Lottery962SalesPage />} />
//           <Route path="/pick-4-3693-method" element={<Pick4Lottery3693SalesPage />} />
//           <Route path="/pick-3-317-method" element={<Pick3Lottery317RundownSalesPage />} />
//           <Route path="/super-pick-3-workout" element={<SuperPick3WorkoutSalesPage />} />
//           <Route path="/pick-3-369-rundown" element={<Pick369RundownSalesPage />} />
//           <Route path="/pick-4-3693-method-texas" element={<Pick4StrategyTexasSalesPage />} />
//           <Route path="/pick-4-3693-method-large-format" element={<Pick4LargeFormat3693SalesPage />} />
//           <Route path="/cash-4-lottery-strategy" element={<Cash4LotteryStrategySalesPage />} />
//           <Route path="/pick-3-mastery" element={<Pick3MasterySalesPage />} />
//           <Route path="/pick3-lottery-strategy-workbook" element={<Pick3LotteryStrategyWorkbookSalesPage />} />
//           <Route path="/cash-4-large-format" element={<Cash4LargeFormatSalesPage />} />
//           <Route path="/lottery-calendar" element={<GridComponent />} />
//           <Route path="/paywall" element={<Paywall />} />
//           <Route path="/success" element={<Success />} />
//           <Route path="/cancel" element={<Cancel />} />
//           <Route path="/home" element={<HomePage />} />

//           <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
//           <Route path="/pick-3" element={<ProtectedRoute><Pick3Generator /></ProtectedRoute>} />
//           <Route path="/grid4x4" element={<ProtectedRoute><Grid4 /></ProtectedRoute>} />
//           <Route path="/statepick3gen" element={<ProtectedRoute><Pick3StateGenerator /></ProtectedRoute>} />
//           <Route path="/730-rundown" element={<ProtectedRoute><SevenThirtyRundownMethod /></ProtectedRoute>} />
//           <Route path="/3693-rundown" element={<ProtectedRoute><ThreeSixNineThreeGenerator /></ProtectedRoute>} />
//           <Route path="/pick-4" element={<ProtectedRoute><Pick4Generator /></ProtectedRoute>} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// }

// export default App;
import React, { useEffect } from 'react';

const RedirectPage = () => {
  useEffect(() => {
    window.location.href = 'https://lnumerislotterystrategy.netlify.app';
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>Redirecting...</h1>
      <p>If you are not redirected automatically, <a href="https://lnumerislotterystrategy.netlify.app">click here</a>.</p>
    </div>
  );
};

export default RedirectPage;
