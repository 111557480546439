import React, { useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';

const AuthContext = React.createContext();

// Custom hook to use AuthContext
export function useAuth() {
  return useContext(AuthContext);
}

// AuthProvider Component
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [credits, setCredits] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Monitor authentication state
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userEmail = user.email || user.reloadUserInfo?.email || null;

        setCurrentUser({
          uid: user.uid,
          email: userEmail,
        });

        const userRef = doc(db, 'users', user.uid);

        // Monitor Firestore document in real-time
        const unsubscribeUser = onSnapshot(
          userRef,
          async (docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              setCredits(data.credits || 0);
              setIsSubscribed(data.isSubscribed || false);
              setStripeCustomerId(data.stripeCustomerId || null);
            } else {
              console.warn(`Firestore document missing for user: ${user.uid}`);
              try {
                // Automatically create Firestore document if missing
                await setDoc(userRef, {
                  email: userEmail,
                  credits: 0,
                  isSubscribed: false,
                  stripeCustomerId: null,
                  createdAt: new Date().toISOString(), // Added timestamp
                });
                console.info(`Firestore document created for user: ${user.uid}`);
              } catch (error) {
                console.error('Error creating Firestore document:', error);
              }
              // Reset state if document is missing and not created
              setCredits(0);
              setIsSubscribed(false);
              setStripeCustomerId(null);
            }
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching user data from Firestore:', error);
            setLoading(false);
          }
        );

        // Clean up Firestore listener
        return () => {
          unsubscribeUser();
        };
      } else {
        // Reset state when user logs out or is unauthenticated
        setCurrentUser(null);
        setCredits(0);
        setIsSubscribed(false);
        setStripeCustomerId(null);
        setLoading(false);
      }
    });

    // Clean up authentication listener
    return () => {
      unsubscribeAuth();
    };
  }, []);

  // Provide context value
  const value = {
    currentUser,
    credits,
    isSubscribed,
    stripeCustomerId,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  );
}
